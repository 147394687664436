<template>
  <div class="dynamic-monitoring-alarm">
    <!-- 月度统计分析表 -->
    <div class="form-area table-search">
      <el-form
        class="demo-form-inline"
        ref="form"
        :model="form"
        label-position="left"
        label-width="82px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="所属车队：" prop="companyId">
          <companySelectForSearch
            ref="companySelectForSearch"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>

        <el-form-item label="车牌号：">
          <car-tree @getData="getVehIds" ref="carTree"></car-tree>
        </el-form-item>

        <el-form-item label="开始时间：" prop="beginTime">
          <el-date-picker
            v-model="form.beginTime"
            type="date"
            placeholder="选择开始时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间：" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="date"
            placeholder="选择结束时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item class="alarm-btn">
          <el-button
            size="small"
            type="primary"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import {
  checkTimeEqualLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDay,
  getCurent
} from '@/common/utils/index'

import { addExcelExport } from '@/api/lib/refreshAlarm.js'
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    })
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }

    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.endTime)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.beginTime, value)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    return {
      form: {
        system: sessionStorage.getItem('system').toString(), //对应系统
        beginTime: new Date() - 3400 * 1000 * 24, // 开始时间
        endTime: new Date() - 3400 * 1000 * 24, // 结束时间
        companyId: null,
        vehicleNos: [],
      },
      rules: {
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      downLoadStatus: false,
      
    }
  },
  created () {
    this.form.system = sessionStorage.getItem('system').toString();
  },
  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val[0];
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val]
    },
    //导出
    exportData () {
      if (!this.form.companyId && !this.form.vehicleNos.length) {
        this.$message.error("请选择所属公司或车辆");
        return
      }
      let exportData = {
        ...this.form,
        beginTime: formatDay(this.form.beginTime),
        endTime: formatDay(this.form.endTime),
      }
      let data = {
        baseUrl: 'report',
        fileName: `报警月度-${getCurent()}`,
        queryParam: JSON.stringify(exportData),
        queryPath: '/vehicleMonitor/statisticMonthAnalysisExport'
      }
      console.log("看数据格式", exportData);
      this.downLoadStatus = true;
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
  }
}
</script>
<style lang="scss" scoped>
.dynamic-monitoring-alarm {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  @include themify() {
    .table-search {
      background-color: #fff;
      padding: 16px;
    }
  }
}
</style>